@import '../../../../../variables.less';

.md-work-week-cont {
  position: relative;
  padding-left: 50px;
}

.md-work-week-avatar {
  position: absolute;
  max-height: 50px;
  max-width: 50px;
  top: 21px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 20px;
}

.md-work-week-name {
  font-size: 16px;
}

.md-work-week-title {
  font-size: 12px;
  margin-top: 5px;
}

.md-switching-timeline-view-cont .mbsc-segmented {
  max-width: 350px;
  margin: 0 auto;
  padding: 1px;
}

.md-work-week-picker {
  flex: 1 0 auto;
}

.md-work-week-nav {
  width: 200px;
}

.md-switching-timeline-view-cont .mbsc-timeline-resource {
  display: flex;
  align-items: center;
}

.md-switching-timeline-view-cont .mbsc-timeline-resource,
.md-switching-timeline-view-cont .mbsc-timeline-resource-col {
  width: 205px;
}

/* material header order */

.mbsc-material.my-calendar-nav-class.md-work-week-prev {
  order: 1;
}

.mbsc-material.my-calendar-nav-class.md-work-week-next {
  order: 2;
}

.mbsc-material.my-calendar-nav-class.md-work-week-nav {
  order: 3;
}

.mbsc-material.my-calendar-nav-class.md-work-week-picker {
  order: 4;
}

.mbsc-material.my-calendar-nav-class.md-work-week-today {
  order: 5;
}

/* windows header order */

.mbsc-windows.my-calendar-nav-class.md-work-week-nav {
  order: 1;
}

.mbsc-windows.my-calendar-nav-class.md-work-week-prev {
  order: 3;
}

.mbsc-windows.my-calendar-nav-class.md-work-week-next {
  order: 5;
}

.mbsc-windows.my-calendar-nav-class.md-work-week-picker {
  order: 2;
}

.mbsc-windows.my-calendar-nav-class.md-work-week-today {
  order: 4;
}




.mbsc-timeline-resource {
  height: 80px !important;
}
.margin16 {
  margin: 16px 16px 0 16px;
}
.ddlShiftsClass{
    width: 100%
}

.required-field::after {
  color: red;
  content: " *";
}

.inputDateClass {
  width: 150px;


}

.floatLeftClass{
    float:left !important;
}

.widthClass{
    width: 100%;
}

.expansionPanelHeader{
  height: 32px!important;
}

.radio-group-class {
  display: flex;
  flex-direction: column;
}

.noPaddingLeftRight {
  padding-left: 0px;
  padding-right: 0px;
}

.noPaddingTop {
  padding-top: 0;
}

.paddingTop {
  padding-top: 15px;
}

.flexClass{
    display: flex;
}


#employeeShiftAssignment .mbsc-timeline-column:hover::after {
  content: '+';
  position: absolute;
  width: 100%;
  font-size: 50px;
  text-align: center;
  line-height: 50px;
  top: 40%;
  margin-top: -15px;
  color: white;
}

#employeeShiftAssignment .mbsc-timeline-column:hover {
  background-color: RGBA(253,112,55, 1.0);
}

 
.mbsc-popup.mbsc-windows.mbsc-popup-center {
  height: 640px !important;
  max-height: 850px !important;
}

.noMarginTopBottom{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}





/*.my-calendar-nav-class {
  background-color: @secondary;
  //box-shadow: 2px 2px #AAAAAA !important;
  border: 1px solid @control-border;
}

.my-calendar-nav-class:hover {
  background-color: @primary;
}

.my-calendar-nav-class-2 .border-class:hover {
  background-color: @primary;
}
*/


.md-work-week-nav {
  font-size: 1em !important;
}

/*
div.border-class {
  border: 1px solid @control-border;
  display: inline;
  float: left;
  margin-left: 5px;
  background-color: @secondary;
}
*/
