@import "./variables.less";

.mat-calendar-body-selected {
  background-color: @primary;
  color: white;
}

.mat-datepicker-toggle-active {
  color: @primary;
}

.mat-drawer-container {
  width: 100%;
  margin: -15px;
  padding: 0;
  height: calc(100vh - 60px);
}

.mat-drawer-inner-container {
  overflow-x: hidden;
}

.mat-drawer-content {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.mat-drawer.mat-drawer-end {
  width: 98.5%;
}

.mat-card {
  cursor: pointer;

  .mat-card-header-text {
    display: block;
    width: 100%;
    text-align: center;
  }
}

:not(.mat-form-field-infix) > .mat-select {
  border: solid 1px @control-border;
  padding: 5px 10px;
}


.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: @primary !important;
  color: #fff;
}

// .mat-select-value-text {
//   color: #495057;
// }

.mat-step-header:hover {
  .mat-step-icon {
    background-color: darken(@primary, 20%);
  }
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: @primary;
}

.mat-step-icon {
  height: 30px;
  width: 30px;
}

.mat-step-icon .mat-icon,
.mat-step-icon-content {
  font-weight: bold;
}

.mat-horizontal-stepper-header-container {
  margin-right: 40px;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: @primary;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: @primary;
}

.mat-button-toggle {
  padding: 0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 30px !important;
}

.mat-button-toggle-checked {
  background-color: @primary;
  color: white !important;
}

.input-group-text.disabled {
  background-color: transparent;
  border: none;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.mat-slide-toggle-thumb {
  background-color: @primary;
}

.mat-slide-toggle.mat-checked:not(.mat-slide-toggle-impartial) .mat-slide-toggle-bar {
  background-color: lighten(@primary, 15%);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: @primary;
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  box-shadow: 1px 1px 3px #000;
  background-color: @primary;

  .mat-slider-thumb-label-text {
    color: white;
  }

  .level-0& {
    background-color: darken(#0f0, 15%);
  }

  .level-1& {
    background-color: darken(#4f0, 15%);
  }

  .level-2& {
    background-color: darken(#8f0, 10%);
  }

  .level-3& {
    background-color: darken(#af0, 10%);
  }

  .level-4& {
    background-color: darken(#cf0, 5%);
  }

  .level-5& {
    background-color: darken(#fc0, 5%);
  }

  .level-6& {
    background-color: darken(#fa0, 10%);
  }

  .level-7& {
    background-color: darken(#f80, 10%);
  }

  .level-8& {
    background-color: darken(#f40, 15%);
  }

  .level-9& {
    background-color: darken(#f00, 15%);
  }
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
  background-color: #c8c8c8;
}

#time-picker-wrapper #time-picker {

  .time-picker-header {

    .time-picker-selected-time {
      div.selected {
        color: @primary !important;
      }
    }

    .time-picker-selected-ampm {
      div.selected {
        color: white !important;
        background: @primary !important;
        border-radius: 15px;
        padding: 2px 6px;
      }
    }
  }

  .time-picker-clock {
    .time-picker-clock-origin {
      background: @primary !important;
    }

    .time-picker-clock-arrow {
      background: @primary !important;

      span {
        background: @primary !important;
      }
    }

    & > button.active {
      background: @primary !important;
      color: white;
    }
  }
}

.allow-cr {
  white-space: pre-line;
}

.color-picker {
  border-radius: 4px;
}

.mat-checkbox-disabled {
  opacity: 60%;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  color: #fff;
}

// .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
//   color: @primary;
// }

// .mat-button.mat-primary,
// .mat-icon-button.mat-primary,
// .mat-stroked-button.mat-primary {
//   // color: @primary;
// }

// .mat-fab.mat-primary,
// .mat-flat-button.mat-primary,
// .mat-mini-fab.mat-primary,
// .mat-raised-button.mat-primary {
//   background-color: @primary;
// }


.small .mat-standard-chip,
.small.mat-standard-chip {
  padding: 4px 9px !important;
  min-height: 24px;
  font-size: 80%;
  font-weight: 400;
}

.mat-form-field.mat-form-field-appearance-outline[dense] {
  .mat-form-field-flex > .mat-form-field-infix {
    padding: 0.4em 0px !important;
    padding-bottom: 12px !important;
  }

  .mat-form-field-label-wrapper {
    top: -1.5em;
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.mat-form-field.mat-form-field-appearance-fill[dense] {
  .mat-form-field-wrapper {
    margin-top: 21px;

    .mat-form-field-flex {
      line-height: 0.75 !important;
      padding-top: 0 !important;

      .mat-form-field-infix {
        padding: 0 !important;
        border-top: 0.54375em solid transparent;
      }
      
      .mat-form-field-suffix {
        padding-bottom: 10px;
        top: -2px;
      }
    }
  }
}

.mat-tab-group.mat-background-primary .mat-tab-header {
  background-color: @primary !important;
}

.mat-tab-label-active {
  opacity: 1 !important;
}

.extended-fab-button {
  width: unset !important;
  padding: 0 16px !important;
  border-radius: 29px !important;
  height: 56px !important;

  .mat-icon {
    margin-right: 10px !important;
  }

  .extended-fab-button__text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: medium !important;
    letter-spacing: 1.25px !important;
    text-transform: uppercase !important;
  }
}

.extended-fab-button.extended-fab-small {
  padding: 0.25rem 0.75rem !important;
  height: 2.5em !important;
  line-height: normal;
  .mat-button-wrapper {
    padding: 0 !important;
  }
}


.mat-button-text {
  font-weight: 300 !important;
  letter-spacing: .1em !important;
  text-transform: uppercase !important;
}

.mat-input-no-message .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-input-no-message.dense-field .mat-form-field-wrapper {
  margin-bottom: 0 !important;
}

.dense-field {
  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-form-field-wrapper {
    margin-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 1em;
  }

  .mat-form-field-infix {
    padding: .5em 0 .5em 0;
    bottom: 3px;
    border-top-width: 0.95em;
  }
  .mat-form-field-suffix, .mat-form-field-prefix {
      top: -0.20em !important;
  }
}


.pseudo-label {
  font-size: 11px;
  color: rgba(0,0,0,0.6);
  display: inline-flex;
  position: absolute;
  left: 4px;
  top: -8px;
  background: white;
  padding: 0 5px;
}

.form-field-readonly .mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}

.form-field-readonly.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0,0,0,.6) !important;
}

.form-field-readonly.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  // color: rgba(0,0,0,0) !important;
}

.textarea.form-field-readonly.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0,0,0,.12) !important;
}

.form-field-readonly.mat-form-field-disabled {
  .mat-form-field-outline-gap {
      border-top-color: transparent !important;
  }
  .mat-form-field-empty.mat-form-field-label {
      transform: translateY(-1.59375em) scale(.75) !important;
      width: 133.33333% !important;
  }
}
.form-field-readonly.mat-form-field-disabled:not(.form-field-readonly-nodash) .mat-form-field-infix::after {
  content: '--';
  opacity: 0.8;
  position: absolute;
  top: 13px;
  z-index: 5;
  left: 0;
  width: 100%;
}

.form-field-readonly.mat-form-field-disabled.mat-form-field-should-float .mat-input-element {
  background: #fff;
  z-index: 6;
  position: relative;
}

.form-field-readonly.form-field-chip-size .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 1 !important;
}

.form-field-chip-size .mat-form-field-infix {
  padding: 9px 0px !important;
}

.form-field-chip-size:not(.form-field-chip-text) input {
  display: none;
}

.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 32px;
  height: 1px;
}

.mat-progress-bar-background, .mat-progress-bar-buffer {
  fill: lighten(@primary, 25%) !important;
  background: lighten(@primary, 25%) !important;
}

.mat-progress-bar-fill::after {
  background-color: @primary;
}

.mat-light-text {
  color: rgba(0, 0, 0, 0.75);
}

.flex-spread {
  flex-grow: 1;
  height: 0;
}

.mat-tab-group-colgrow .mat-tab-body-wrapper {
  flex-grow: 1;
  height: 0;
}

.noresize {
  resize: none;
}

.dense-field.mat-form-field-type-mat-chip-list {
  .mat-form-field-wrapper {
    margin: 0 !important;
  }
  .mat-form-field-infix {
    height: 42.75px !important;
  }
  .mat-chip {
    font-size: .75em;
    min-height: 24px;
    padding: 2px 8px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
.mat-chip-list.chip-list-nowrap {
  .mat-chip-list-wrapper {
    flex-wrap: nowrap;
    overflow: hidden;
  }
}

.mat-tab-group {
    .mat-tab-label {
      font-weight: medium;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1.25px;
    }
}

.mat-tab-group-headerstyle {
  & > .mat-tab-header {
    border-bottom: 0 !important;
    .mat-tab-list .mat-ink-bar {
      background: #fff !important;
    }
    .mat-tab-labels {
      background: @dark;
      .mat-tab-label {
        color: #fff;
      }
    }
  }
}

.mat-form-field input[readonly], .mat-form-field textarea[readonly] {
  cursor: default;
}

.mat-form-field.mat-select-readonly {
  * {
    cursor: default;
  }
  .mat-select {
    .mat-select-arrow {
      display: none;
    }
  }
}

.mat-form-field textarea {
  resize: none !important;
}

.mat-checkbox-readonly, .mat-slide-toggle-readonly {
  cursor: default;
  pointer-events: none;
}

.mat-checkbox-no-margin .mat-checkbox-layout {
  margin-bottom: 0 !important;
}

.mat-checkbox.mat-checkbox-display {
  pointer-events: none;
  .mat-checkbox-frame {
    border-color: currentColor !important;
  }
  .mat-checkbox-background {
    background-color: currentColor !important;
  }
  .mat-checkbox-checkmark-path {
    stroke: #fff !important;
  }
}

.mat-checkbox.mat-checkbox-display:not(.mat-checkbox-checked) {
  .mat-checkbox-background {
    background-color: transparent !important;
  }
}


.mat-small-header {
  font-weight: 500;
  font-size: 0.8rem;
}
.font-weight-medium {
  font-weight: 500;
}

.text-ellipsis {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-fab-small {
  height: 36px !important;
  width: 36px !important;

  .mat-button-wrapper {
    padding: 0 !important;
  }
}

.mat-textarea-h-100 {
  &.mat-form-field, .mat-form-field-wrapper, .mat-form-field-flex, .mat-form-field-infix, textarea {
    height: 100%;
  }
}

.mat-button-base.mat-square-button {
  min-width: 36px !important;
  height: 36px !important;
  line-height: normal !important;
  padding: 0 !important;
}

.mat-fab.mat-button-disabled {
  background-color: #e0e0e0 !important;
}

.mat-table-no-bottom-border {
  thead:last-child, tbody, tfoot {
    tr:last-child {
      th, td {
        border-bottom: 0 !important;
      }
    }
  }
}

.mat-border-bottom {
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.item--process {
  color: #1A237E !important;
}
.item--labor {
  color: #827717 !important;
}
.item--product {
  color: #009688 !important;
}
.item--material {
  color: #EF6C00 !important;
}
.item--purchased {
  color: #039BE5 !important;
}
.item--paint {
  color: #673AB7 !important;
}

.mat-button-base .mat-icon {
  line-height: normal !important;
}

.flex-grow-2 {
  flex-grow: 2 !important;
}

.mat-selectable {
  cursor: pointer;
  &:hover, &.selected {
    background-color: @primary;
    color: #fff;
  }
}
.history-chip-estimated {
  background-color: #FFF59D !important;
  color: #212121 !important;
}
.history-chip-quoted {
  background-color: #FFB74D !important;
  color: #212121 !important;
}
.history-chip-purchased {
  background-color: #F44336 !important;
  color: #fff !important;
}
.history-chip-gold {
  background-color: #9C27B0 !important;
  color: #fff !important;
}

.mat-button-small {
  line-height: 26px !important;
  font-size: 12px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.microtask-indicator {
  border-radius: 100%;
  width: 10px;
  height: 10px;
}
.progress--not_started {
  background-color: @danger;
}
.progress--in_progress {
  background-color: @warning;
}
.progress--done {
  background-color: @success;
}
.progress--not_applicable {
  background-color: #6f42c1;
}
.progress--needs_quote {
  background-color: #0dcaf0;
}

.mat-icon.mat-icon-small {
  width: 16px;
  height: 14px;
  font-size: 16px;
}

.mat-muted {
  color: rgba(0, 0, 0, 0.60);
}

.mat-radio-no-margin .mat-radio-label {
  margin-bottom: 0 !important;
}
