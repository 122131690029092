/* You can add global styles to this file, and also import other style files */
@import "./variables.less";
// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./material.less";

//--------- mobileScroll control styles:
@import "./app/cots/employee/components/employee-shift-assignment/employee-shift-assignment.component.less";

.h-100 {
  height: auto !important;
  min-height: 100% !important;
}

.min-vh-100 {
  min-height: 100vh;
}

.min-vh-80 {
  min-height: 80vh;
}

.margin-top-15p {
  margin-top: 15%;
}

.display-inline {
  display: inline !important;
}

.btn-primary {
  background: @primary !important;
  border-color: darken(@primary, 20%);

  &:hover {
    background: darken(@primary, 10%) !important;
    border-color: darken(@primary, 30%);
  }
}

.badge-secondary {
  color: #666;
}

.cursor-help {
  cursor: help;
}

input.ng-invalid.ng-touched {
  border: solid 1px red;
  border-bottom-width: 3px;
  background: #ff000009;
}

.mat-form-field-appearance-outline input.ng-invalid.ng-touched {
  border: none;
  border-bottom-width: 0;
  background: transparent;
}

textarea {
  resize: none;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: @primary !important;
}

.bg-secondary,
.badge-secondary,
.btn-secondary {
  background-color: @secondary !important;
}

.border-secondary {
  border-color: @secondary !important;
}

.bg-light {
  background-color: @light !important;
}

table.search-results {
  font-size: 0.9em;

  tr {
    border: solid 1px #ccc;
    border-left: none;
    border-right: none;

    &:hover {
      background: @primary !important;
      color: white;
      cursor: pointer;
    }

    &:nth-child(odd) {
      background: #F6F7F8;
    }

    td {
      padding: 7px;
    }
  }

  thead {
    th {
      background: white !important;

      &:hover {
        color: white;
      }
    }

    tr:hover {
      th {
        background: @primary !important;
      }
    }
  }
}

.outlet.container-fluid {
  .navHeader {
    margin-top: -65px;
    height: 50px;
    margin-left: -1.6666666%;
    padding-top: 3px;
    z-index: 9;
  }

  &.min {
    .navHeader {
      width: calc(100% - 16.66666666% + 16px);
    }
  }

  &.max {
    .navHeader {
      width: calc(100% - 53px);
    }
  }
}

.bg-primary {
  background: @primary !important;
  color: #fff !important;
}

.text-primary {
  color: #FD7037 !important;
}

.mr-6 {
  margin-right: 4.5rem !important;
}

.badge {
  border-radius: 50px;

  h2 {
    padding: 0.1em 0.5em;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

a.btn.btn-badge {
  background: @primary;
  width: 48px;
  height: 48px;
  text-align: center;
  left: auto;
  right: 10px;
  bottom: 10px;
  font-size: 4em;
  color: white !important;
  padding: 0;
  line-height: 32px;
  border-radius: 24px;
  font-weight: bold;
  border: solid 1px darken(@primary, 20%);

  &.has-icon {
    line-height: 15px;
  }

  i.fas,
  i.fab,
  i.fa {
    font-size: 30px;
  }

  &.bg-success {
    border-color: darkgreen;
  }

  &.bg-danger {
    border-color: darkred;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 6px;
  }
}

.form-group {

  label:not(.mat-form-field-label) {
    font-weight: bold;
    font-size: 0.7em;
  }

  .search {
    button {
      background: #F6F7F8;
      color: #333;
      font-size: 0.75em;
    }
  }
}

.form-control {
  &::placeholder {
    font-size: 0.9em;
    font-style: italic;
    color: #ccc;
  }

  &:not([readonly]):focus {
    border-color: @primary;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(253, 112, 55, .6);
  }

  &[readonly]:focus {
    border-color: transparent;
    box-shadow: none;
  }
}

.btn-search:hover {
  background: @primary !important;
  color: white !important;
  border-color: darken(@primary, 20%);
}

.form-control.form-control-plaintext[readonly] {
  background-color: transparent;
}

h5 {
  color: #5C656F !important;
  font-weight: normal;
}

.input-group-text {
  border-color: @control-border;
  border-radius: 0 !important;
}

.form-control {
  border-color: @control-border;
  border-radius: 0;

  &.form-control-sm {
    border-radius: 0;
  }

  &.form-control-plaintext {
    border-color: transparent !important;
  }
}

// Used in resizable directive
.resizableHandle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  opacity: 0.4;
  cursor: nwse-resize;

  &::before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border-right: 3px black double;
    transform: rotate(45deg);
  }
}

.owl-dt-calendar-control .owl-dt-calendar-control-content {
  padding: 30px;
}

span.owl-dt-control-content.owl-dt-control-button-content {
  text-transform: uppercase;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: rgba(255, 255, 255, .85);
  background-color: @primary;
}

button.owl-dt-control.owl-dt-control-button.owl-dt-control-arrow-button:first-child {
  position: absolute;
  right: 48px;
}

button.owl-dt-control.owl-dt-control-button.owl-dt-control-period-button {
  position: absolute;
  left: 12px;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 23.25em;
}

.owl-dt-inline-container {
  box-shadow: none;
  border: solid 1px @text;
  border-radius: 4px;
}

th.owl-dt-weekday span {
  font-size: 1.25em !important;
  width: 2ch !important;
  display: inline-block;
  overflow: hidden;
  letter-spacing: 2.0em;
  text-align: center;
}

.owl-dt-calendar-multi-year-table thead.owl-dt-calendar-header tr {
  border-top: solid 1px @light;
}

.owl-dt-calendar-multi-year-table thead.owl-dt-calendar-header tr th {
  font-size: 0;
}

// Give modal overlay container a higher z-index: this ensures it prevents clicking on fixed-bottom buttons,
// such as the "Are You Finished?" dialog when saving a Quote
.cdk-overlay-container {
  z-index: 1031;
}

.border-primary {
  border-color: @primary !important;
}

.cdk-overlay-container {
  z-index: 99999;
}


// ---------------- mbsc-eventcalendar -----------------------
.mbsc-segmented-button.mbsc-button {
  background-color: @secondary !important;
}

.mbsc-segmented-button.mbsc-button.mbsc-selected {
  background-color: #7A7A7A !important;
}

div.border-class {
  border: 1px solid @control-border;
  display: inline;
  float: left;
  margin-left: 5px;
  background-color: @secondary;
}

.my-calendar-nav-class .mbsc-calendar-button {
  color: black !important;
  background-color: @secondary !important;
  border: 1px solid @control-border !important;
}

.my-calendar-nav-class :hover .mbsc-calendar-button {
  color: white !important;
  background-color: @primary !important;
  border: 1px solid @control-border;
  opacity: unset !important;
}

div.md-work-week-picker > mbsc-segmented-group > mbsc-segmented:nth-child(n) > label > mbsc-button {
  border: 1px solid @control-border !important;
}

div.md-work-week-picker > mbsc-segmented-group > mbsc-segmented:nth-child(n):hover > label > mbsc-button {
  color: white !important;
  background-color: @primary !important;
  border: 1px solid @control-border !important;
}

.my-calendar-nav-class-2 .border-class:hover .mbsc-calendar-button {
  color: white !important;
  background-color: @primary !important;
  opacity: unset !important;
}

.mbsc-schedule-event-background::after {
  background: unset !important;
}

.button-text {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.h-100-t {
  height: 100% !important;
}

carousel {
  z-index: auto !important;
}

#sentry-feedback {
  --bottom: auto;
  --top: 0rem;
  --right: 10rem;
}
